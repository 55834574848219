<template>
<div v-if="Access('SettingsView')" class="CookiesMainView">
  <div class="BigPlaceholder">
    <div class="BigContainer">
      <div class="BigHeader" style="overflow: hidden;">
        <CookiesTabs :Tabs="Tabs" style="margin: 0.8em;"></CookiesTabs>
      </div>
        <div class="BigBody">
          <router-view></router-view>
        </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data () {
    return {
      Tabs: [
        { Name: this.LanguageSelector('System', 'الاعدادات'), Link: '/Settings/System', Value: 'System' },
        { Name: this.LanguageSelector('Account', 'الحساب'), Link: '/Settings/Account', Value: 'Account' }
      ]
    }
  }
}
</script>
<style scoped>
/* --------------------------------------------------------------Tabs */
.TabButton{
  width: 100%;
  padding: 0.8em 1.5em;
  text-decoration: none;
  color: v-bind('$store.getters.Theme.color3');
}

a.router-link-exact-active{
  background: v-bind('$store.getters.Theme.BG2');
  color: v-bind('$store.getters.Theme.color1');
}
/* --------------------------------------------------------------Tabs */
.BigPlaceholder{
  width: 75%;
  padding: 10px;
  /* min-height: 800px; */
  /* height: 80vh; */
}

@media (max-width: 1200px) {
  .BigPlaceholder{
    width: 100%;
    padding: 5px 0px;
  }
}
.BigContainer{
  background: v-bind('$store.getters.Theme.BG2');
  border-radius: var(--rad1);
  filter: drop-shadow(var(--shadow1));
  width: 100%;
  /* height: 100%; */
  justify-content: center;
  text-align: center;
  /* display: inline-table; */
  margin: auto;
  display: flex;
  flex-direction: column;
}

.BigHeader{
  width:100%;
  /* height:60px; */
  /* background-color: var(--shade2); */
  border-radius: var(--rad1) var(--rad1) 0 0;
  text-align: left;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.BigBody{
  font-size: 0.85rem;
  border-radius: var(--rad1);
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  height: 70vh;
  overflow-y: scroll;
}

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Search */
.CookiesSearchInput{
  border-radius: 100px;
  padding: 0.5em 1em;
  margin: 0.6em 1em;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 1em;
  background: v-bind('$store.getters.Theme.Light3');
}
.CookiesSearchInput::placeholder{
  color: v-bind('$store.getters.Theme.Shade3');
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Search */

.DialogHeaderButton{
  padding: 0.6em 1.3em;
  margin: 0.6em 0.3em;
  /* margin: 10px 3px; */
  /* position: absolute; */
  left: 1em;
  font-weight: 500;
}

.BigHeaderText{
  margin: auto 1em auto 1.5em;
}
@media (max-width: 500px) {
  .CookiesMainView {
      padding-right: 3px;
      padding-left: 3px;
  }
  .BigHeader{
    display: flex;
    flex-direction: column;
  }
  .BigHeader>>>*{
    font-size: 1.6em !important;
  }
  .DialogHeaderButton{
    margin-left: auto;
    margin-right: 1em;
  }
  .CookiesSearchInputContainer{
    margin: auto !important;
  }

}

@media (max-width: 1000px) {
  .BigPlaceholder>>>*{
    font-size: 2.5vw;
  }
}
td, th{
  border-left: solid 0.5px rgba(136, 136, 136, 0.1);
  white-space: nowrap;
  padding: 0.9em 0.1em;
  /* display: inline-flex; */
  margin: auto;
  max-width: 23%;
}
tr{
  width: 100%;
}
tr:nth-child(even){
  background-color: var(--shade0);
}
table { border-collapse: 0px; }
td:first-child { border-radius: var(--rad3) 0px 0px var(--rad3); border: none !important;}
td:last-child { border-radius: 0px var(--rad3) var(--rad3) 0px; }
th:first-child { border: none !important;}

.CarsHeader{
  cursor: pointer;
}

.NoteCell{
  max-width: 10em;
  overflow: hidden;
  outline: none;
  cursor: text;
}
.NoteCell:focus{
  background: var(--color2);
}
.SettingsContainer{
  overflow: hidden;
}
</style>
